<template>
  <div class="signup">
    <a-row>
      <a-col :xs="0" :sm="0" :md="14" :lg="14" :xl="14" class="left">
        <div class="banner">
        </div>
      </a-col>
      <a-col :xs="24" :sm="24" :md="10" :lg="10" :xl="10" class="right">
        <div class="signup-section">
          <div class="welcome">Welcome to</div>
          <div class="msg">{{title}}</div>
          <div class="reg-form">
            <div class="title"><b>User</b> Registration</div>
            <a-form ref="formRef" :model="formState" :rules="rules" layout="vertical" @finish="signup" @finishFailed="handleFinishFailed">
              <a-form-item label="Client Name" class="client-name" required has-feedback name="client_name">
                <a-input v-model:value="formState.client_name" type="text" size="large" placeholder="Enter client name" allow-clear autocomplete="off" />
              </a-form-item>
              <a-form-item label="First Name" class="first-name" required has-feedback name="first_name">
                <a-input v-model:value="formState.first_name" size="large" placeholder="Enter your first name" allow-clear autocomplete="off" />
              </a-form-item>
              <a-form-item label="Last Name" class="last-name" required has-feedback name="last_name">
                <a-input v-model:value="formState.last_name" size="large" placeholder="Enter your last name" allow-clear autocomplete="off" />
              </a-form-item>
              <a-form-item label="Email" class="email" required has-feedback name="email">
                <a-input v-model:value="formState.email" size="large" placeholder="Enter your email" allow-clear autocomplete="off" />
              </a-form-item>
              <a-form-item label="Phone" class="phone" required has-feedback name="phone">
                <a-input v-model:value="formState.phone" size="large" placeholder="Enter your phone number" allow-clear autocomplete="off" />
              </a-form-item>
              <a-form-item label="Address" class="address" has-feedback name="address">
                <VueGoogleAutocomplete
                  id="map"
                  classname="client-address"
                  placeholder="Start typing"
                  v-on:placechanged="getAddressData"
                ></VueGoogleAutocomplete>
              </a-form-item>
              <a-form-item>
                <a-button type="primary" size="large" block class="signup-btn" html-type="submit" :loading="loading">
                  Register
                </a-button>
              </a-form-item>
            </a-form>
            <div class="signin">Already have an account? <span class="link" @click="login">Login</span></div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script lang="ts">
import { defineComponent, reactive, ref, UnwrapRef } from 'vue'
import { notification } from 'ant-design-vue'
import { ValidateErrorEntity } from 'ant-design-vue/es/form/interface'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

import validate from '../services/validator'
import router from './../router'
import IClient from './../interface/profile'
import profileService from './../services/profile'

export default defineComponent({
  components: {
    VueGoogleAutocomplete
  },
  setup () {
    const title = ref<string>(process.env.VUE_APP_PROJECT_NAME)
    const addressExist = ref<boolean>(false)
    const loading = ref<boolean>(false)
    const rules = validate.signupValidation
    const formRef = ref()
    const formState: UnwrapRef<IClient> = reactive({
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      profile_image: '',
      client_name: '',
      client_logo: '',
      certificate_access: true,
      created_by: '',
      profile_type: '',
      is_active: true,
      status: 'unpaid',
      send_login: true,
      address: '',
      address_details: {}
    })
    const getAddressData = async (data) => {
      const coordinates = []
      coordinates.push(data.latitude)
      coordinates.push(data.longitude)
      formState.address_details.country = data.country
      formState.address_details.location = { coordinates: coordinates, type: 'Point' }
      formState.address_details.address1 = data.route
      formState.address_details.address2 = data.locality
      formState.address_details.postal_code = data.postal_code
      console.log('formState', formState)
      addressExist.value = true
    }
    const notify = async (message: string, description: string, type: string) => {
      notification[type]({ message, description })
    }
    const resetForm = () => {
      formRef.value.resetFields()
    }
    const createClient = async (values: IClient) => {
      console.log('signup', values)
      try {
        values.send_login = true
        loading.value = true
        if (addressExist.value) {
          console.log('values.address_details', values.address_details)
          const address = await profileService.addAddress(values.address_details)
          values.address = address.data._id
        } else {
          await notify('Error', 'Address is required to create client', 'error')
          loading.value = false
          return
        }
        const responce = await profileService.createNewClient(values)
        if (typeof responce.data === 'string') {
          await notify('Error', responce.data, 'error')
        } else {
          resetForm()
          await notify('Success', 'Your account has been setuped and login credentials send to your email id.', 'success')
        }
        loading.value = false
      } catch (error) {
        loading.value = false
        await notify('Error', error.data, 'error')
      }
    }
    const signup = async () => {
      console.log('signup', formState)
      createClient(formState)
    }
    const handleFinishFailed = (errors: ValidateErrorEntity<IClient>) => {
      console.log('errors', errors)
    }
    const login = async () => {
      router.push('/login')
    }
    return {
      formState,
      signup,
      rules,
      loading,
      handleFinishFailed,
      login,
      getAddressData,
      formRef,
      title
    }
  }
})
</script>

<style lang="scss">
.signup {
  .left {
    .banner {
      background-image: url("../assets/images/signup.png");
      background-repeat: no-repeat;
      background-size: cover;
      height: 100vh;
    }
  }
  .right {
    .signup-section {
      margin-top: 60px;
      text-align: left;
      .welcome {
        color: #545454;
        font-size: 20px;
        padding-left: 25px;
      }
      .msg {
        color: #38B6FF;
        font-size: 25px;
        font-weight: 600;
        margin: 5px 0 15px 0;
        padding-left: 25px;
      }
      .reg-form {
        background: #7670D908;
        padding: 30px;
        border-radius: 25px;
        width: 90%;
        .title {
          color: #484848;
          font-size: 23px;
          margin-bottom: 15px;
        }
        .ant-form {
          .ant-input-affix-wrapper {
            border: 1px solid #38B6FF;
            border-radius: 10px;
          }
          .client-address {
            width: 100%;
            border: 1px solid;
            padding: 6.5px 11px;
            border: 1px solid #38B6FF;
            border-radius: 10px;
          }
          .signup-btn {
            background: #38B6FF;
            background: linear-gradient(90deg, #38B6FF 0%, #2f68f6 100%);
            border-radius: 10px;
            border-color: #38B6FF;
          }
        }
        .signin {
          color: #6B6B6B;
          text-align: center;
          .link {
            color: #38B6FF;
            font-weight: 600;
          }
        }
      }
    }
  }
}
@media only screen and (max-width: 992px) {
  .signup {
    .right {
      .signup-section {
        .reg-form {
          width: 100% !important;
        }
      }
    }
  }
}
</style>
